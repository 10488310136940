<template>
  <v-container
    grid-list-sm
    class="mt-15"
  >
    <EventsCards
      :config="config"
    />
  </v-container>
</template>

<script>
import {
  mdiPlus, mdiChevronDown, mdiClockOutline,
  mdiMapMarkerOutline,
} from '@mdi/js'
import EventsCards from './EventsCards.vue'

export default {
  components: {
    EventsCards,
  },
  setup() {
    return {
      urlRegister: `${process.env.VUE_APP_URL}register`,
      urlS3: process.env.VUE_APP_URL_S3,

      // icons
      icons: {
        mdiPlus,
        mdiChevronDown,
        mdiClockOutline,
        mdiMapMarkerOutline,
      },
    }
  },
  data() {
    return {
      config: {
        name: '',
      },
    }
  },
  created() {
    this.getConfig()
  },
  methods: {
    getConfig() {
      this.axios
        .get('configuration-view/data-general')
        .then(res => {
          if (res.data.success) {
            this.config = res.data.data.data.data

            // this.$vuetify.theme.themes.light.primary = this.config.color
          }
        })
    },
  },
}
</script>

<style scoped>
@font-face {
  font-family: OpenSansLight;
  src: url(../../../assets/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../../../assets/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../../../assets/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;
}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1a5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

.punt55Mine {
  font-size: 40px;
}

.punt55MineMov {
  font-size: 28px;
}

.punt32Mine {
  font-size: 20px;
}

.punt32MineMov {
  font-size: 20px;
}

.capLetterMine {
  text-transform: uppercase;
}
</style>
